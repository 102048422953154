// ==========================================:
import React from 'react';
import logo from 'assets/dist/img/logo-footer.svg';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { popUpOpen } from '../../redux/reducers/popUp/reducer';

const Footer = () => {
	const dispatch = useDispatch();
	return (
		<footer className="footer">
			<div className="footer-container">
				{/* <a href="!#" className="logo">
					<img alt="Logo" src={logo} />
				</a> */}
				<div className="footer__support">
					<a href="mailto:support@kaiserex.com">support@kaiserex.com</a>
				</div>
				<div className="footer-nav">
					<ul>
						<li>
							<a href="/terms" target="_blank">
								Service Agreement
							</a>
						</li>
						<li>
							<a href="/privacy" target="_blank">
								Privacy Policy
							</a>
						</li>
						{/* <li>
							<button
								onClick={() => {
									dispatch(popUpOpen('supportSuccess'));
								}}
								type="submit"
								id="footer_support_btn"
							>
								Support
							</button>
						</li> */}
					</ul>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
