import React, { useLayoutEffect } from 'react';

import WalletsTable from 'components/tables/WalletsTable';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserReferralLoader,
	getUserWallets,
	getUserWalletsCrypto,
	getUserWalletsFiat,
} from 'redux/reducers/users/selectors';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { getUserWalletsRequest } from 'redux/reducers/users/reducer';
import { useParams } from 'react-router';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import { ECoinType } from 'components/tables/WalletsTable/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import IconSvg from '../../../../ui/Svg/IconSvg';
import { currencyDataRequest } from '../../../../redux/reducers/currency/reducer';
import Loading from '../../../../layouts-elements/Loading/Loading';

const BalancesTabView = () => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();
	const wallet = useSelector(getUserWallets);
	const walletsCryptoList = useSelector(getUserWalletsCrypto);
	const loading = useSelector(getUserReferralLoader);
	const walletsFiatList = useSelector(getUserWalletsFiat);
	const permissions = useSelector(getPermissions);

	useLayoutEffect(() => {
		dispatch(getUserWalletsRequest({ user_id: userId }));
	}, [dispatch, userId]);
	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	return (
		<div className="user-management user-management-balance">
			<div className="user-management-body">
				<div className="wallet-content-balance-wrap">
					<div className="wallet-content-balance">
						<div className="wallet-content-balance-item">
							<div className="wallet-content-balance-item__title">
								<p>Estimates Balance</p>
							</div>
							<div className="wallet-content-balance-item__value">
								<p>
									{loading ? (
										<Loading height={29} />
									) : (
										<>
											{roundingNumber((wallet?.fiat?.eur || 0) + (wallet?.crypto?.eur || 0))}{' '}
											<span> EUR</span>
										</>
									)}
								</p>
							</div>
							<div className="wallet-content-balance-item__user-currensy">
								<p>
									{loading ? (
										<Loading height={29} fontSize={16} />
									) : (
										<>
											≈{' '}
											{roundingNumber((wallet?.fiat?.btc || 0) + (wallet?.crypto?.btc || 0), 'BTC')}{' '}
											<span>BTC</span>
										</>
									)}
								</p>
							</div>
						</div>

						<div className="wallet-content-balance-item">
							<div className="wallet-content-balance-item__title">
								<p>Fiat Balance</p>
							</div>
							<div className="wallet-content-balance-item__value">
								<p>
									{loading ? (
										<Loading height={29} />
									) : (
										<>
											{roundingNumber(wallet?.fiat.eur)}
											<span>EUR</span>
										</>
									)}
								</p>
							</div>

							<div className="wallet-content-balance-item__user-currensy">
								<p>
									{loading ? (
										<Loading height={29} fontSize={16} />
									) : (
										<>
											≈ {roundingNumber(wallet?.fiat.btc, 'BTC')}
											<span>BTC</span>
										</>
									)}
								</p>
							</div>
						</div>

						<div className="wallet-content-balance-item">
							<div className="wallet-content-balance-item__title">
								<p>Crypto Balance</p>
							</div>
							<div className="wallet-content-balance-item__value">
								<p>
									{loading ? (
										<Loading height={29} />
									) : (
										<>
											{roundingNumber(wallet?.crypto.eur)}
											<span>EUR</span>
										</>
									)}
								</p>
							</div>
							<div className="wallet-content-balance-item__user-currensy">
								<p>
									{loading ? (
										<Loading height={29} fontSize={16} />
									) : (
										<>
											≈ {roundingNumber(wallet?.crypto.btc, 'BTC')}
											<span>BTC</span>
										</>
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="wallet-content-tables">
					{!loading && (
						<WalletsTable
							permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
							walletsList={walletsFiatList}
							title="Fiat Balances"
							perPage={5}
							type={ECoinType.FIAT}
						/>
					)}

					{loading ? <Loading /> : null}
					<div className="block--middle mb-8">
						<div className="table-title table-title_crypto">
							<p>Crypto Balances</p>
						</div>
						{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && (
							<button
								onClick={() => {
									const data = {
										title: `Send to Pool Wallet`,
										payload: {
											type: 'All user balances transfer wallet',
											asset_id: 10,
											userId,
										},
									};
									dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
									dispatch(setPopUpData(data));
								}}
								type="button"
								className="button--send-pool-wallet"
							>
								Send All to Pool Wallet
								<div className="button--send-pool-wallet_arrow">
									<IconSvg name="keyboard-arrow-right" w="7" h="13" />
								</div>
							</button>
						)}
					</div>
					{!loading && (
						<WalletsTable
							permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
							walletsList={walletsCryptoList}
							title=""
							perPage={5}
							type={ECoinType.CRYPTO}
						/>
					)}

					{loading ? <Loading /> : null}
				</div>
			</div>
		</div>
	);
};

export default BalancesTabView;
