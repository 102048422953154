import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { IBankAccountRequestPayloadWithId } from 'redux/reducers/bankAccounts/types';

export interface IPaymentMethodFiatItem {
	id: number;
	bank_nickname: string;
	beneficiary_address: string;
	beneficiary_name: string;
	bank_address: string;
	bank_name: string;
	account_number: string;
	swift_bic: string;
	note: string;
	permission: boolean;
	deleteHandler: (id: number, nickname: string) => void;
	updateHandler: (obj: IBankAccountRequestPayloadWithId) => void;
	setUpdatingBankHandler?: any;
}

const PaymentMethodFiatItem: FC<IPaymentMethodFiatItem> = ({
	id,
	bank_nickname,
	beneficiary_address,
	beneficiary_name,
	bank_address,
	bank_name,
	swift_bic,
	account_number,
	note,
	deleteHandler,
	updateHandler,
	permission,
	setUpdatingBankHandler,
}) => {
	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	// console.log(id);
	const switchOnChangeableMode = () => {
		// setChangeableMode(true);
		setUpdatingBankHandler(id);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		beneficiary_name: beneficiary_name || '',
		bank_address: bank_address || '',
		bank_name: bank_name || '',
		swift_bic: swift_bic || '',
		account_number: account_number || '',
		note: note || '',
	};

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							bank_account_id: id,
							data: { ...values, bank_nickname, beneficiary_address },
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Beneficiary Name</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="text"
									name="beneficiary_name"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.beneficiary_name}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Currency</p>
								<p>???</p>
								<span className="td-more-info">???</span>
								{/* <div className="input"> */}
								{/*	<div className="input-wrapper"> */}
								{/* <input */}
								{/*	className="input-item input-item---fullWidth input-item--type-td" */}
								{/*	name="bank_address" */}
								{/*	onChange={props.handleChange} */}
								{/*	onBlur={props.handleBlur} */}
								{/*	value={props.values.bank_address} */}
								{/*	type="text" */}
								{/*	placeholder="0" */}
								{/* /> */}
								{/*	</div> */}
								{/* </div> */}
							</div>
							<div className="td">
								<p className="td-hidden-name">Bank Details</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="input-item input-item---fullWidth input-item--type-td"
											name="bank_name"
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props.values.bank_name}
											type="text"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Account Number</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="input-item input-item---fullWidth input-item--type-td"
											name="account_number"
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props.values.account_number}
											type="text"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										className="btn--icon-danger btn--cancel"
										type="button"
										onClick={switchOffChangeableMode}
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button className="btn--icon-success btn--check" type="submit" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Beneficiary Name</p>
						<p>{beneficiary_name}</p>
					</div>
					<div className="td td-colum">
						<p className="td-hidden-name">Currency</p>
						<p>???</p>
						<span className="td-more-info ml-0">???</span>
					</div>
					<div className="td">
						<p className="td-hidden-name">Bank Details</p>
						<p>{bank_name}</p>
					</div>
					<div className="td td-colum">
						<p className="td-hidden-name">Account Number</p>
						<p>{account_number}</p>
						<span className="td-more-info ml-0">SWIFT: {swift_bic}</span>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								<>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										className="btn--icon btn--edit"
										type="button"
										onClick={switchOnChangeableMode}
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										className="btn--icon btn--delete"
										type="button"
										onClick={() => deleteHandler(id, beneficiary_name)}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default PaymentMethodFiatItem;
