/* eslint-disable @typescript-eslint/naming-convention */

import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import {
	IWalletAddressChain,
	IWalletAddressRequestPayload,
} from 'redux/reducers/walletAddresses/types';

export interface IPaymentMethodCryptoItem {
	id: number;
	address: string;
	permission: boolean;
	nickname: string;
	chain: IWalletAddressChain;
	asset: any;
	tag: string | null;
	deleteHandler: (id: number, nickname: string) => void;
	updateHandler: (obj: IWalletAddressRequestPayload) => void;
}

const PaymentMethodCryptoItem: FC<IPaymentMethodCryptoItem> = ({
	id,
	nickname,
	chain,
	address,
	tag,
	deleteHandler,
	updateHandler,
	permission,
	asset,
}) => {
	const [changeableMode, setChangeableMode] = useState<boolean>(false);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		nickname: nickname || '',
		address: address || '',
		tag: tag || '',
	};
	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							address_id: id,
							data: { ...values },
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr tr--editable" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Wallet Nickname</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="text"
									name="nickname"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.nickname}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Cryptocurrency</p>
								<p>{asset?.code.toUpperCase()}</p>
								<span className="td-more-info">{capitalizeFirstLetter(asset?.name)}</span>
							</div>
							<div className="td">
								<p className="td-hidden-name">Network</p>
								<p>{chain?.name === chain?.code ? chain?.name : `${chain?.name} ${chain?.code}`}</p>
							</div>

							<div className="td">
								<p className="td-hidden-name">Wallet Address</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="input-item input-item---fullWidth input-item--type-td"
											name="address"
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props.values.address}
											type="text"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										className="btn--icon btn--cancel"
										type="button"
										onClick={switchOffChangeableMode}
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button className="btn--icon btn--check" type="submit" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Wallet Name</p>
						<p>{nickname}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Cryptocurrency</p>
						<p>{asset?.code.toUpperCase()}</p>
						<span className="td-more-info">{capitalizeFirstLetter(asset?.name)}</span>
					</div>
					<div className="td">
						<p className="td-hidden-name">Network</p>
						<p>
							<p>{chain?.name === chain?.code ? chain?.name : `${chain?.name} ${chain?.code}`}</p>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Wallet Address</p>
						<p>{address}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								<>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										className="btn--icon btn--edit"
										type="button"
										onClick={switchOnChangeableMode}
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										className="btn--icon btn--delete"
										type="button"
										onClick={() => deleteHandler(id, nickname)}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default PaymentMethodCryptoItem;
