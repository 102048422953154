/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { ChangeEvent, FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
	createDepositAccountRequest,
	updateDepositAccountRequest,
} from 'redux/reducers/walletBalance/reducer';
import { getDepositAccounts } from 'redux/reducers/walletBalance/selectors';
import { IBankAccountRequestPayload } from 'redux/reducers/bankAccounts/types';
import { navListAfterLogin } from 'routes/routesList';
import { getFeeRequest } from 'redux/reducers/fee/reducer';
import { IDepositAccount } from 'redux/reducers/walletBalance/types';
import { notificationsInfoFields } from 'services/utils/inputFields/ipuntFields';
import { getFeeStoreData } from 'redux/reducers/fee/selectors';
import { ICountry } from '../CreateUserTables/UserTable';
import CountriesSelect from '../../../ui/Formik/Select/CountriesSelect';
import CurrencySelect from '../../../ui/Formik/Select/CurrencySelect';

export interface IBankAccountForm {
	id: number | null;
	closeForm: () => void;
	setPage: (page: number) => void;
}

interface IInitVals {
	bank_nickname: string;
	bank_name: string;
	bank_address: string;
	bank_country: string;
	beneficiary_address: string;
	beneficiary_name: string;
	beneficiary_country: string;
	swift_bic: string;
	asset: string;
	account_number: string | number;
	note: string | undefined | null;
}

const CreateBankAccount: FC<IBankAccountForm> = ({ id, closeForm, setPage }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const depositAccounts = useSelector(getDepositAccounts);

	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [countries, setCountries] = useState<ICountry[]>([]);
	const currencies = useSelector(getFeeStoreData)?.data.data;
	const [selectedBeneficiaryCountry, setSelectedBeneficiaryCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedBankCountry, setSelectedBankCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedCurrency, setSelectedCurrency] = useState();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [initVals, setInitVals] = useState<IInitVals>();

	useLayoutEffect(() => {
		fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			});
		const data = {
			per_page: 100,
			current_page: 1,
			type: 'fiat',
		};
		dispatch(getFeeRequest(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [depositAccount, setDepositAccount] = useState<IDepositAccount | null>(null);

	const initialValues = {
		bank_nickname: depositAccount?.bank_nickname || '',
		bank_name: depositAccount?.bank_name || '',
		bank_address: depositAccount?.bank_address || '',
		bank_country: '',
		beneficiary_address: depositAccount?.beneficiary_address || '',
		beneficiary_name: depositAccount?.beneficiary_name || '',
		beneficiary_country: '',
		swift_bic: depositAccount?.swift_bic || '',
		asset: '',
		account_number: depositAccount?.account_number || '',
		note: depositAccount?.note,
	};

	useLayoutEffect(() => {
		if (id) {
			const bankAccount = depositAccounts.data.filter((el: IDepositAccount) => el.id === id);
			setDepositAccount(bankAccount[0]);
			setSelectedCurrency(bankAccount[0].asset);
			setInitVals({
				bank_nickname: bankAccount[0]?.bank_nickname,
				bank_name: bankAccount[0]?.bank_name,
				bank_address: bankAccount[0]?.bank_address,
				bank_country: '',
				beneficiary_address: bankAccount[0]?.beneficiary_address,
				beneficiary_name: bankAccount[0]?.beneficiary_name,
				beneficiary_country: '',
				swift_bic: bankAccount[0]?.swift_bic,
				asset: bankAccount[0].asset.code,
				account_number: bankAccount[0]?.account_number,
				note: bankAccount[0]?.note,
			});
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [depositAccounts, id]);

	// useLayoutEffect(() => {
	// 	console.log(initVals);
	// }, [initVals]);

	const validationSchema = yup.object().shape({
		bank_nickname: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_nickname.required)
			.min(1, notificationsInfoFields.validationMessages.bank_nickname.min)
			.max(160, notificationsInfoFields.validationMessages.bank_nickname.max),
		bank_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_name.required)
			.min(1, notificationsInfoFields.validationMessages.bank_name.min)
			.max(60, notificationsInfoFields.validationMessages.bank_name.max),
		bank_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_address.required)
			.min(1, notificationsInfoFields.validationMessages.bank_address.min)
			.max(95, notificationsInfoFields.validationMessages.bank_address.max),
		bank_country: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_country.required),
		beneficiary_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_address.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_address.min)
			.max(90, notificationsInfoFields.validationMessages.beneficiary_address.max),
		beneficiary_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_name.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_name.min)
			.max(60, notificationsInfoFields.validationMessages.beneficiary_name.max),
		beneficiary_country: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_country.required),
		asset: yup.string().required(notificationsInfoFields.validationMessages.asset.required),
		swift_bic: yup
			.string()
			.required(notificationsInfoFields.validationMessages.swift_bic.required)
			.min(8, notificationsInfoFields.validationMessages.swift_bic.min)
			.max(11, notificationsInfoFields.validationMessages.swift_bic.max),
		account_number: yup
			.string()
			.required(notificationsInfoFields.validationMessages.account_number.required)
			.min(5, notificationsInfoFields.validationMessages.account_number.min)
			.max(34, notificationsInfoFields.validationMessages.account_number.max),
		note: yup.string().max(160, notificationsInfoFields.validationMessages.note.max).nullable(),
	});

	const handlePasteValidation = (
		e:
			| React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
			| ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		validateField: (field: string) => void,
	) => {
		const { name } = e.currentTarget;
		setTimeout(() => {
			validateField(name);
		});
	};

	const handleWholeFormChange = (values: any) => {
		if (id) {
			if (JSON.stringify(initVals) === JSON.stringify(values)) {
				setButtonDisabled(true);
			} else {
				setButtonDisabled(false);
			}
		}
		// console.log(JSON.stringify(initVals));
		// console.log(JSON.stringify(values));
	};

	return (
		<>
			<div>
				<div className="title-block title-block--deposit">
					<button type="button" className="btn btn--icon btn--back" onClick={closeForm} />
					<p className="table-title">{id ? 'Update' : 'Add'} Bank Account</p>
				</div>
			</div>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				validateOnBlur={false}
				enableReinitialize
				validateOnChange={false}
				onSubmit={(values, actions) => {
					actions.setSubmitting(false);
					const assetId = currencies.find((item: any) => item.code === values.asset).id;
					id
						? dispatch(
								updateDepositAccountRequest({
									...values,
									admin_requisite_id: Number(id),
									asset_id: assetId,
								}),
						  )
						: dispatch(createDepositAccountRequest({ data: { ...values, asset_id: assetId } }));
					closeForm();
					setPage(1);
				}}
			>
				{({
					values,
					handleBlur,
					handleChange,
					errors,
					setFieldTouched,
					touched,
					validateField,
					isValid,
					dirty,
					setFieldValue,
				}) => (
					<>
						{handleWholeFormChange(values)}
						<Form className="form form--type2">
							<div className="form-body">
								<div className="input">
									<div className="input input--row">
										<p className="input__name">Bank Nickname</p>
										<div className="input-wrapper">
											<input
												className={`input-item ${errors.bank_nickname ? 'error-border' : ''}`}
												name="bank_nickname"
												type="text"
												placeholder="Enter Bank Nickname"
												onChange={(e) => {
													handleChange(e);
													handlePasteValidation(e, validateField);
												}}
												onBlur={handleBlur}
												onPaste={(e) => handlePasteValidation(e, validateField)}
												value={values.bank_nickname}
											/>
											<div className="error-red">
												<ErrorMessage
													className="error-message"
													name="bank_nickname"
													component="span"
												/>
											</div>
										</div>
									</div>
									<div className="input input--row">
										<p className="input__name">Beneficiary Name</p>
										<div className="input-wrapper">
											<input
												className={`input-item ${errors.beneficiary_name ? 'error-border' : ''}`}
												name="beneficiary_name"
												type="text"
												placeholder="Enter Beneficiary Name"
												onChange={(e) => {
													handleChange(e);
													handlePasteValidation(e, validateField);
												}}
												onBlur={handleBlur}
												onPaste={(e) => handlePasteValidation(e, validateField)}
												value={values.beneficiary_name}
											/>
											<div className="error-red">
												<ErrorMessage
													className="error-message"
													name="beneficiary_name"
													component="span"
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="input input--row">
									<p className="input__name">Beneficiary Address</p>
									<div className="input-wrapper">
										<input
											className={`input-item ${errors.beneficiary_address ? 'error-border' : ''}`}
											name="beneficiary_address"
											placeholder="Enter Beneficiary Address"
											type="text"
											onChange={(e) => {
												handleChange(e);
												handlePasteValidation(e, validateField);
											}}
											onBlur={handleBlur}
											onPaste={(e) => handlePasteValidation(e, validateField)}
											value={values.beneficiary_address}
										/>
										<div className="error-red">
											<ErrorMessage
												className="error-message"
												name="beneficiary_address"
												component="span"
											/>
										</div>
									</div>
								</div>

								<div className="input">
									<div className="input input--row">
										<p className="input__name">Beneficiary Country</p>

										<div className="input-wrapper">
											<Field
												type="text"
												searchField
												name="beneficiary_country"
												component={CountriesSelect}
												arr={countries}
												setTouched={() => setFieldTouched('beneficiary_country')}
												touched={touched.beneficiary_country}
												resetCustomSelect={resetCustomSelect}
												setResetCustomSelect={setResetCustomSelect}
												validateField={validateField}
											/>

											<div className="error-red">
												<ErrorMessage
													className="error-message"
													name="beneficiary_country"
													component="span"
												/>
											</div>
										</div>
									</div>

									<div className="input input--row">
										<p className="input__name">Bank Name</p>
										<div className="input-wrapper">
											<input
												className={`input-item ${errors.bank_name ? 'error-border' : ''}`}
												name="bank_name"
												type="text"
												placeholder="Enter Bank Name"
												onChange={(e) => {
													handleChange(e);
													handlePasteValidation(e, validateField);
												}}
												onPaste={(e) => handlePasteValidation(e, validateField)}
												onBlur={handleBlur}
												value={values.bank_name}
											/>
											<div className="error-red">
												<ErrorMessage className="error-message" name="bank_name" component="span" />
											</div>
										</div>
									</div>
								</div>
								<div className="input input--row">
									<p className="input__name">Bank Address</p>
									<div className="input-wrapper">
										<input
											className={`input-item input-high ${
												errors.bank_address ? 'error-border' : ''
											}`}
											name="bank_address"
											placeholder="Enter Bank Address"
											type="text"
											onChange={(e) => {
												handleChange(e);
												handlePasteValidation(e, validateField);
											}}
											onBlur={handleBlur}
											onPaste={(e) => handlePasteValidation(e, validateField)}
											value={values.bank_address}
										/>
										<div className="error-red">
											<ErrorMessage
												className="error-message"
												name="bank_address"
												component="span"
											/>
										</div>
									</div>
								</div>
								<div className="input">
									<div className="input input--row">
										<p className="input__name">Bank Country</p>

										<Field
											type="text"
											searchField
											name="bank_country"
											component={CountriesSelect}
											arr={countries}
											resetCustomSelect={resetCustomSelect}
											setResetCustomSelect={setResetCustomSelect}
											validateField={validateField}
										/>
										<div className="error-red">
											<ErrorMessage
												className="error-message"
												name="bank_country"
												component="span"
											/>
										</div>
									</div>

									<div className="input input--row">
										<p className="input__name ">Currency</p>
										<Field
											type="text"
											searchField
											name="asset"
											component={CurrencySelect}
											arr={currencies}
											resetCustomSelect={resetCustomSelect}
											setResetCustomSelect={setResetCustomSelect}
											validateField={validateField}
											activeValue={selectedCurrency}
										/>
										<div className="error-red">
											<ErrorMessage className="error-message" name="asset" component="span" />
										</div>
									</div>
								</div>

								<div className="input input--row">
									<p className="input__name ">SWIFT/BIC</p>
									<div className="input-wrapper">
										<input
											className={`input-item ${errors.swift_bic ? 'error-border' : ''}`}
											name="swift_bic"
											type="text"
											placeholder="Enter SWIFT / BIC"
											onChange={(e) => {
												handleChange(e);
												handlePasteValidation(e, validateField);
											}}
											onBlur={handleBlur}
											value={values.swift_bic}
											onPaste={(e) => handlePasteValidation(e, validateField)}
										/>
										<div className="error-red">
											<ErrorMessage className="error-message" name="swift_bic" component="span" />
										</div>
									</div>
								</div>
								<div className="input input--row">
									<p className="input__name">Account Number</p>
									<div className="input-wrapper ">
										<input
											className={`input-item ${errors.account_number ? 'error-border' : ''}`}
											name="account_number"
											type="text"
											placeholder="Enter Account Number"
											onChange={(e) => {
												handleChange(e);
												handlePasteValidation(e, validateField);
											}}
											onBlur={handleBlur}
											onPaste={(e) => handlePasteValidation(e, validateField)}
											value={values.account_number}
										/>
										<div className="error-red">
											<ErrorMessage
												className="error-message"
												name="account_number"
												component="span"
											/>
										</div>
									</div>
								</div>

								<div className="input input--row mb-30">
									<p className="input__name ">Notes</p>
									<div className="input-wrapper">
										<textarea
											className={`input-item textarea-item ${errors.note ? 'error-border' : ''}`}
											name="note"
											placeholder="Enter your text here"
											onChange={(e) => {
												handleChange(e);
												handlePasteValidation(e, validateField);
											}}
											onKeyUp={(e: any) => {
												if (e.target.value === '') {
													setFieldValue('note', null);
												}
											}}
											onBlur={handleBlur}
											onPaste={(e) => handlePasteValidation(e, validateField)}
											value={values.note}
										/>
										<div className="error-red">
											<ErrorMessage className="error-message" name="note" component="span" />
										</div>
									</div>
								</div>
								<button
									type="submit"
									className="btn btn--min-h-52 btn-primary btn--full"
									disabled={!isValid || !dirty || buttonDisabled}
								>
									{id ? 'Update' : 'Add'} Bank Account
								</button>
							</div>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default CreateBankAccount;
