import { FC, useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { notificationsInfoFields } from 'services/utils/ipuntFields/ipuntFields';
import { getBankAccounts } from '../../../../redux/reducers/bankAccounts/selectors';
import { ICountry } from '../../../tables/CreateUserTables/UserTable';
import Input from '../../../../ui/Formik/Input';
import CountriesSelect from '../../../../ui/Formik/Select/CountriesSelect';
import InputPattern from '../../../../ui/Formik/Input/inputPattern';
import { IBankAccountRequestPayload } from '../../../../redux/reducers/fee/types';
import { updateBankAccountRequest } from '../../../../redux/reducers/bankAccounts/reducer';

interface IPaymentMethodUpdateBank {
	setDisableUpdatingBankHandler: () => void;
	bankAccountId: number | null;
}

interface IBankAccountData {
	account_number: string | number;
	bank_address: string;
	bank_country: string;
	bank_name: string;
	bank_nickname: string;
	beneficiary_address: string;
	beneficiary_country: string;
	beneficiary_name: string;
	created_at: string | number;
	deleted_at: string | number | null;
	id: number | null;
	note: string | number;
	swift_bic: string | number;
	updated_at: string | number;
	user_id: string | number;
}

const PaymentMethodUpdateBank: FC<IPaymentMethodUpdateBank> = ({
	setDisableUpdatingBankHandler,
	bankAccountId,
}) => {
	const dispatch = useDispatch();
	const bankAccountsData = useSelector(getBankAccounts);
	// const [bankAccountData, setBankAccountData] = useState<IBankAccountData>();
	const [countries, setCountries] = useState<[] | ICountry[]>([]);
	const [selectedBeneficiaryCountry, setSelectedBeneficiaryCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedBankCountry, setSelectedBankCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	useLayoutEffect(() => {
		fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			})
			.catch((err) => console.log(err));
	}, []);

	const initialValues = {
		bank_nickname: '',
		beneficiary_name: '',
		beneficiary_address: '',
		beneficiary_country: '',
		bank_name: '',
		bank_address: '',
		bank_country: '',
		swift_bic: '',
		account_number: '',
		note: '',
	};

	const [initialInputValues, setInitialInputValues] = useState<any>(initialValues);

	// useLayoutEffect(() => {
	// 	setBankAccountData(
	// 		bankAccountsData.filter((item: IBankAccountData) => item.id === bankAccountId),
	// 	);
	// }, [bankAccountId, bankAccountsData]);

	// useLayoutEffect(() => {
	// 	console.log(bankAccountsData);
	// }, [bankAccountsData]);

	useLayoutEffect(() => {
		if (bankAccountId && countries && bankAccountsData) {
			const bankAccount = bankAccountsData.filter(
				(el: IBankAccountData) => el.id === bankAccountId,
			);
			setInitialInputValues(bankAccount[0]);
			const beneficiaryCountry = countries.find(
				(item) => item.value === bankAccount[0].beneficiary_country,
			);
			const bankCountry = countries?.find((item) => item.value === bankAccount[0].bank_country);
			beneficiaryCountry && setSelectedBeneficiaryCountry(beneficiaryCountry);
			bankCountry && setSelectedBankCountry(bankCountry);
		}
	}, [bankAccountsData, bankAccountId, countries]);

	const validationSchema = yup.object().shape({
		bank_nickname: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_nickname.required)
			.min(1, notificationsInfoFields.validationMessages.bank_nickname.min)
			.max(160, notificationsInfoFields.validationMessages.bank_nickname.max),
		beneficiary_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_name.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_name.min)
			.max(60, notificationsInfoFields.validationMessages.beneficiary_name.max),
		beneficiary_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_address.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_address.min)
			.max(90, notificationsInfoFields.validationMessages.beneficiary_address.max),
		beneficiary_country: yup
			.string()
			.nullable()
			.required(notificationsInfoFields.validationMessages.beneficiary_country.required),
		bank_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_name.required)
			.min(1, notificationsInfoFields.validationMessages.bank_name.min)
			.max(60, notificationsInfoFields.validationMessages.bank_name.max),
		bank_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_address.required)
			.min(1, notificationsInfoFields.validationMessages.bank_address.min)
			.max(95, notificationsInfoFields.validationMessages.bank_address.max),
		bank_country: yup
			.string()
			.nullable()
			.required(notificationsInfoFields.validationMessages.bank_country.required),
		swift_bic: yup
			.string()
			.required(notificationsInfoFields.validationMessages.swift_bic.required)
			.min(8, notificationsInfoFields.validationMessages.swift_bic.min)
			.max(11, notificationsInfoFields.validationMessages.swift_bic.max),
		account_number: yup
			.string()
			.required(notificationsInfoFields.validationMessages.account_number.required)
			.min(5, notificationsInfoFields.validationMessages.account_number.min)
			.max(34, notificationsInfoFields.validationMessages.account_number.max),
		note: yup.string().max(160, notificationsInfoFields.validationMessages.note.max).nullable(true),
	});

	const onSubmit = (obj: any) => {
		dispatch(updateBankAccountRequest(obj));
		setDisableUpdatingBankHandler();
		// console.log(obj);
	};

	const handleWholeFormChange = (values: any) => {
		if (bankAccountId) {
			if (
				JSON.stringify({
					...values,
					beneficiary_country: selectedBeneficiaryCountry?.value,
					bank_country: selectedBankCountry?.value,
				}) === JSON.stringify(initialInputValues)
			) {
				setButtonDisabled(true);
			} else {
				setButtonDisabled(false);
			}
		}
	};

	return (
		<div className="account-details">
			<div className="account-header">
				<div className="title-block-wrap">
					<div className="title-block">
						<p className="title">User Management</p>
					</div>
					<div className="title-block">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="back-btn" onClick={setDisableUpdatingBankHandler} />
						<p className="title title--type2">Update Bank Account</p>
					</div>
				</div>
			</div>
			<Formik
				validationSchema={validationSchema}
				initialValues={initialInputValues}
				onSubmit={(values, { resetForm, setSubmitting }) => {
					onSubmit({
						bank_account_id: bankAccountId,
						data: {
							...values,
							beneficiary_country: selectedBeneficiaryCountry?.value,
							bank_country: selectedBankCountry?.value,
						},
					});
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({ setFieldTouched, touched, values, errors, isValid, dirty, setFieldValue }) => (
					<>
						{handleWholeFormChange(values)}
						<Form className="form form--type4">
							<div className="form-body">
								<div className="input-wrap input-wrap--col-2">
									<Field
										title="Bank Nickname"
										type="text"
										placeholder="Enter Bank Nickname"
										name="bank_nickname"
										required
										component={Input}
									/>
									<Field
										title="Beneficiary Name"
										type="text"
										placeholder="Enter Beneficiary Name"
										name="beneficiary_name"
										required
										component={Input}
									/>
								</div>
								<Field
									title="Beneficiary Address"
									type="text"
									placeholder="Enter Beneficiary Address"
									name="beneficiary_address"
									required
									component={Input}
								/>
								<div className="input-wrap input-wrap--col-2">
									<div className="input">
										<p className="input__name">Beneficiary Country</p>
										<Field
											title="Beneficiary Country"
											type="text"
											placeholder="Select Country"
											searchField
											name="beneficiary_country"
											component={CountriesSelect}
											arr={countries}
											onChange={setSelectedBeneficiaryCountry}
											setTouched={() => setFieldTouched('beneficiary_country')}
											touched={touched.beneficiary_country}
											resetCustomSelect={resetCustomSelect}
											setResetCustomSelect={setResetCustomSelect}
											activeValue={selectedBeneficiaryCountry || undefined}
										/>
										{/* {touched.beneficiary_country && !values.beneficiary_country && (
											<div className="input-notify input-notify--absolute">
												<span className="input-notify__char">*</span>
												<span className="input-notify__text">{errors.beneficiary_country}</span>
											</div>
										)} */}
									</div>
									<Field
										title="Bank Name"
										type="text"
										placeholder="Enter Bank Name"
										name="bank_name"
										required
										component={Input}
									/>
								</div>
								<Field
									title="Bank Address"
									type="text"
									placeholder="Enter Bank Address"
									name="bank_address"
									required
									component={Input}
								/>
								<div className="input-wrap input-wrap--col-2">
									<div className="input">
										<p className="input__name">Bank Country</p>
										<Field
											title="Bank Country"
											type="text"
											placeholder="Select Country"
											dropdownTitle="Select Country"
											name="bank_country"
											searchField
											component={CountriesSelect}
											arr={countries}
											setTouched={() => setFieldTouched('bank_country')}
											touched={touched.bank_country}
											onChange={setSelectedBankCountry}
											resetCustomSelect={resetCustomSelect}
											setResetCustomSelect={setResetCustomSelect}
											activeValue={selectedBankCountry || undefined}
										/>
										{/* {touched.bank_country && !values.bank_country && (
											<div className="input-notify input-notify--absolute">
												<span className="input-notify__char">*</span>
												<span className="input-notify__text">{errors.bank_country}</span>
											</div>
										)} */}
									</div>
									<Field
										title="SWIFT / BIC"
										type="text"
										placeholder="Enter SWIFT / BIC"
										name="swift_bic"
										required
										component={InputPattern}
									/>
								</div>
								<Field
									title="Account Number"
									type="text"
									placeholder="Enter Account Number"
									name="account_number"
									required
									component={Input}
								/>
								<Field
									title="Notes (Optional)"
									type="textarea"
									placeholder="Enter your text here"
									name="note"
									component={Input}
									onKeyUp={(e: any) => {
										if (e.target.value === '') {
											setFieldValue('note', null);
										}
									}}
								/>

								<button
									type="submit"
									className="btn btn-primary btn--full"
									style={{ marginTop: 14 }}
									disabled={!isValid || !dirty || buttonDisabled}
								>
									Update Bank Account
								</button>
							</div>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default PaymentMethodUpdateBank;
