import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import {
	getWalletBalancePlatformBalancesUserCrypto,
	getWalletBalancePlatformBalancesUserCryptoLoading,
} from '../../../../redux/reducers/walletBalance/selectors';
import { popUpOpen, setPopUpData } from '../../../../redux/reducers/popUp/reducer';
import Loading from '../../../../layouts-elements/Loading/Loading';

const Total = ({ permission, activeTab }: { permission: boolean; activeTab: string }) => {
	const dispatch = useDispatch();
	const item = useSelector(getWalletBalancePlatformBalancesUserCrypto);
	const loading = useSelector(getWalletBalancePlatformBalancesUserCryptoLoading);
	return (
		<div className="wallet-content-balance flex">
			<div className="wallet-content-balance-item wallet-content-balance-item--blue">
				<div className="wallet-content-balance-item__title">
					<p>Estimates Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{roundingNumber(item?.totalBalances.eur, 'EUR')} <span> EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {roundingNumber(item?.totalBalances.btc, 'BTC')}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>

			<div className="wallet-content-balance-item wallet-content-balance-item--yellow">
				<div className="wallet-content-balance-item__title">
					<p>Fiat Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{roundingNumber(item?.totalBalancesFiat.eur, 'EUR')}
								<span>EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {roundingNumber(item?.totalBalancesFiat.btc, 'BTC')}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>

			<div className="wallet-content-balance-item wallet-content-balance-item--blue-type2">
				<div className="wallet-content-balance-item__title">
					<p>Crypto Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{roundingNumber(item?.totalBalancesCrypto.eur, 'EUR')}
								<span>EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {roundingNumber(item?.totalBalancesCrypto.btc, 'BTC')}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Total;
