import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { getWalletBalancePlatformBalancesUserFiat } from '../../../../redux/reducers/walletBalance/selectors';
import { popUpOpen, setPopUpData } from '../../../../redux/reducers/popUp/reducer';

const TotalFiat = ({ permission, activeTab }: { permission: boolean; activeTab: string }) => {
	const dispatch = useDispatch();
	const item = useSelector(getWalletBalancePlatformBalancesUserFiat);
	return (
		<>
			<div className="wallet-content-balance flex">
				<div className="wallet-content-balance-item wallet-content-balance-item--blue">
					<div className="wallet-content-balance-item__title">
						<p>Estimates Balance</p>
					</div>
					<div className="wallet-content-balance-item__value">
						<p>
							{roundingNumber(item?.totalBalances.eur, 'EUR')}
							<span>EUR</span>
						</p>
					</div>
					<div className="wallet-content-balance-item__user-currensy">
						<p>
							≈ {roundingNumber(item?.totalBalances.btc, 'BTC')}
							<span>BTC</span>
						</p>
					</div>
				</div>
				<div className="wallet-content-balance-item wallet-content-balance-item--yellow">
					<div className="wallet-content-balance-item__title">
						<p>Fiat Balance</p>
					</div>
					<div className="wallet-content-balance-item__value">
						<p>
							{roundingNumber(item?.totalBalancesFiat.eur, 'EUR')}
							<span>EUR</span>
						</p>
					</div>
					<div className="wallet-content-balance-item__user-currensy">
						<p>
							≈ {roundingNumber(item?.totalBalancesFiat.btc, 'BTC')}
							<span>BTC</span>
						</p>
					</div>
				</div>
				<div className="wallet-content-balance-item wallet-content-balance-item--blue-type2">
					<div className="wallet-content-balance-item__title">
						<p>Crypto Balance</p>
					</div>
					<div className="wallet-content-balance-item__value">
						<p>
							{roundingNumber(item?.totalBalancesCrypto.eur, 'EUR')}
							<span>EUR</span>
						</p>
					</div>
					<div className="wallet-content-balance-item__user-currensy">
						<p>
							≈ {roundingNumber(item?.totalBalancesCrypto.btc, 'BTC')}
							<span>BTC</span>
						</p>
					</div>
				</div>
			</div>
			{activeTab === 'fiat' ? null : (
				<>
					{permission && (
						<button
							onClick={() => {
								const data = {
									title: 'Send All to Pool Wallet',
									payload: {
										type: 'User balances all',
									},
								};
								dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
								dispatch(setPopUpData(data));
							}}
							type="button"
							className="button button--long button--size2"
						>
							Send All to Pool Wallet
						</button>
					)}
				</>
			)}
		</>
	);
};
export default TotalFiat;
