import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import { ROUTES } from 'routes/constants';
import { EApiTransactionType } from 'services/api/transactions/types';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import DepositsWithdrawalsTab from './DepositsWithdrawalsTab';
import TransfersLimitsTab from './TransfersLimitsTab';
import TradesTab from './TradesTab';
import TradesLimitsTab from './TradesLimitsTab';
import PendingWithdrawalTab from './PendingWithdrawalTab';
import PendingDepositsTab from './PendingDepositsTab';
import LedgerAggregatedTable from './LedgerAggregatedTable';

const TransfersAndTradeReports = () => {
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Reports</p>
				</div>
			</div>
			<>
				<div className="tabs-buttons-nav tabs-buttons-nav--reports">
					<NavLink to={ROUTES.transfersAndTradeReports.trades.index}>Trades</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.transfers.index}>Transfers</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.deposit.index}>Deposits</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.withdrawals.index}>Withdrawals</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.transferLimits.index}>
						Transfer Limits
					</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.tradesLimits.index}>Trade Limits</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.pendingDeposits.index}>
						Pending Deposits
					</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.pendingWithdrawal.index}>
						Pending Withdrawal
					</NavLink>
					<NavLink to={ROUTES.transfersAndTradeReports.ledgerAggregated.index}>
						Ledger Aggregated
					</NavLink>
				</div>
				<div>
					<Switch>
						<Route path={ROUTES.transfersAndTradeReports.deposit.index}>
							<DepositsWithdrawalsTab type={EApiTransactionType.DEPOSIT} />
						</Route>
						<Route path={ROUTES.transfersAndTradeReports.withdrawals.index}>
							<DepositsWithdrawalsTab type={EApiTransactionType.WITHDRAWAL} />
						</Route>
						<Route path={ROUTES.transfersAndTradeReports.transfers.index}>
							<DepositsWithdrawalsTab type={EApiTransactionType.TRANSFER} />
						</Route>
						<Route
							path={ROUTES.transfersAndTradeReports.transferLimits.index}
							component={TransfersLimitsTab}
						/>
						<Route path={ROUTES.transfersAndTradeReports.trades.index} component={TradesTab} />
						<Route
							path={ROUTES.transfersAndTradeReports.tradesLimits.index}
							component={TradesLimitsTab}
						/>
						<Route
							path={ROUTES.transfersAndTradeReports.pendingDeposits.index}
							component={PendingDepositsTab}
						/>
						<Route
							path={ROUTES.transfersAndTradeReports.pendingWithdrawal.index}
							component={PendingWithdrawalTab}
						/>
						<Route
							path={ROUTES.transfersAndTradeReports.ledgerAggregated.index}
							component={LedgerAggregatedTable}
						/>
						<Redirect to={ROUTES.transfersAndTradeReports.deposit.index} />
					</Switch>
				</div>
			</>
		</>
	);
};

export default TransfersAndTradeReports;
