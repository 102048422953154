import { useFormatDateTime } from 'hooks/useFormatDateTime';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { capitalize, shortNotation } from 'services/utils/strings';
import { roundingNumber } from 'services/utils/roundingNumber';
import IconSvg from 'ui/Svg/IconSvg';
import { ICryptoHistoryRowProps } from 'components/AccountDetails/TransferHistoryTab/CryptoHistory/types';
import { statusTitle } from 'components/AccountDetails/TransferHistoryTab/utils';
import moment from 'moment';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const CryptoHistoryRow: FC<ICryptoHistoryRowProps> = ({ item, type }) => {
	const [date, time] = useFormatDateTime(item.created_at);
	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<div>
					{type === 'deposit' ? (
						<>
							<p>{moment.utc(item.created_at).format('YYYY-MM-DD')}</p>
							<span className="td-more-info">{moment.utc(item.created_at).format('HH:mm:ss')}</span>
						</>
					) : (
						<>
							<p>{date}</p>
							<span className="td-more-info">{time}</span>
						</>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{item.asset.code.toUpperCase()}</p>
				<span className="td-more-info">{item.asset.name}</span>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Gross</p>
				<div>
					<p>{roundingNumber(item?.gross_fee, item.asset.code)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<div>
					<p>{roundingNumber(item.fee, item.asset.code)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Net</p>
				<p>{toFixedNumber(item?.net_fee, item.asset.code, true)}</p>
				<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Destination</p>
				<div className="copy-button copy-button--long">
					{item.destination ? (
						<>
							<span className="copy-button__text">{shortNotation(item.destination, 19, 3)}</span>
							<CopyToClipboard text={item.destination} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="16" h="16" />
									<span>Copy</span>
								</button>
							</CopyToClipboard>
						</>
					) : (
						<span className="copy-button__text">-</span>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx Hash</p>
				<div className="copy-button copy-button--long">
					{item.tx_hash ? (
						<>
							<span className="copy-button__text">{shortNotation(item.tx_hash, 19, 3)}</span>
							<CopyToClipboard text={item.tx_hash} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="16" h="16" />
									<span>Copy</span>
								</button>
							</CopyToClipboard>
						</>
					) : (
						<span className="copy-button__text">-</span>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">TxID</p>
				<p>{item.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<span className={`${item.status}-status`}>{statusTitle[item.status]}</span>
			</div>
		</div>
	);
};

export default CryptoHistoryRow;
