import ResetRequestAdminItem from 'components/ResetRequest/ResetRequestAdminItem';
import React, { FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { getAdminResetDataRequest } from 'redux/reducers/users/reducer';
import { getAdminResetPasswordsRequests } from 'redux/reducers/users/selectors';
import ReactPaginate from 'react-paginate';
import PerPageDropdown from '../../../../../services/utils/PaginationPerPageDropdown';
import Loading from '../../../../../layouts-elements/Loading/Loading';

interface IUsersTableProps {
	handlePageChange: any;
	setItemsPerPageHandler: any;
	itemsPerPage: number;
	page: number;
}

const PasswordTab: FC<IUsersTableProps> = ({
	handlePageChange,
	setItemsPerPageHandler,
	itemsPerPage,
	page,
}) => {
	const requests = useSelector(getAdminResetPasswordsRequests);
	const permisisons = useSelector(getPermissions);

	return (
		<div className="table table--user-management-reset-request--admin">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Admin ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Email</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Type</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Date</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>IP address</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Reason</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{requests?.data.length ? (
					requests?.data?.map((item) => (
						<ResetRequestAdminItem
							key={item.id}
							data={item}
							permission={!!permisisons?.[EPermissionNames.RESET_REQUEST].editable}
						/>
					))
				) : (
					<Loading />
				)}
			</div>
			<div className="table-footer">
				<div className="pagination-block pagination-block--full">
					<PerPageDropdown
						options={[10, 20, 50, 100]}
						setPerPage={setItemsPerPageHandler}
						perPage={itemsPerPage}
					/>
					<ReactPaginate
						breakLabel="..."
						pageCount={requests?.last_page || 1}
						onPageChange={handlePageChange}
						forcePage={page - 1}
						className="pagination"
						activeClassName="active"
						previousClassName="pagination__arrow pagination__arrow--prev"
						nextClassName="pagination__arrow pagination__arrow--next"
						previousLabel=""
						nextLabel=""
					/>
				</div>
			</div>
		</div>
	);
};

export default PasswordTab;
